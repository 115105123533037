import { styled, createTheme } from '@mui/material/styles';

export const segmentLinkTheme = createTheme({
  palette: {
    primary: {
      main: '#595958',
    },
    secondary: {
      main: '#39ffd0',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 30,
  },
});

export const SegmentLink = styled('button')`
  ${({ theme }) => `
cursor: pointer;
font-size: 20px;
padding: 5px 20px 5px 20px;
color: ${theme.palette.secondary.main};
border: none;
background-color: transparent;
transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  })};
   &:hover {
    color: 'white';
    transform: scale(1.3);
  }
&:active {
  color: white;
}
`}
`;
