import * as React from 'react';
import '../../../assets/page-layout/footer.css';

export const Footer: React.FC = () => {
  return (
    <div className='credit-links'>
      <a
        className='links'
        href='https://iconscout.com/icons/github'
        target='_blank'
      >
        Free Github Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/pixel-icons'
        target='_blank'
      >
        Pixel Icons
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/linkedin'
        target='_blank'
      >
        Free Linkedin Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/enamostudios'
        target='_blank'
      >
        Enamo Studios
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/css'
        target='_blank'
      >
        Free Css Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/pixel-icons'
        target='_blank'
      >
        Pixel Icons
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/javascript'
        target='_blank'
      >
        Free Javascript Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/icon-54'
        target='_blank'
      >
        Icon 54
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/typescript'
        target='_blank'
      >
        Free Typescript Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/icon-54'
        target='_blank'
      >
        Icon 54
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/react'
        target='_blank'
      >
        Free React Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a className='links' href='https://iconscout.com/contributors/icon-54'>
        Icon 54
      </a>{' '}
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/graphql'
        target='_blank'
      >
        Free Graphql Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/icon-54'
        target='_blank'
      >
        Icon 54
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://iconscout.com/icons/node-js'
        target='_blank'
      >
        Free Node Js Icon
      </a>{' '}
      <p className='by'>by</p>{' '}
      <a
        className='links'
        href='https://iconscout.com/contributors/icon-mafia'
        target='_blank'
      >
        Icon Mafia
      </a>
      &nbsp; &nbsp; &nbsp;
      <a
        className='links'
        href='https://icons8.com/icon/11260/source-code'
        target='_blank'
      >
        Source Code
      </a>{' '}
      <p className='by'> icon by</p>{' '}
      <a className='links' href='https://icons8.com'>
        Icons8
      </a>
    </div>
  );
};
