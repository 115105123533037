export interface IColours {
  primary: string;
  secondary: string;
  tertiary: string;
}

export const colours: IColours = {
  primary: 'white',
  secondary: '#21201f',
  tertiary: 'black',
};
