import * as React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Link,
  ThemeProvider,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

import {
  cardMediaStyles,
  projectCardTheme,
  StyledProjectCard,
} from '../../../styles/project-card';
import { projectCardButton } from '../../../styles/project-card-buttons';

interface IProjectCardProps {
  image?: string;
  title?: string;
  subtitle_2?: string;
  subtitle_3?: string;
  text_1?: string;
  text_2?: string;
  text_3?: string;
  text_4?: string;
  externalLink_1?: string;
  externalLink_2?: string;
}

export const ProjectCard: React.FC<IProjectCardProps> = ({
  image,
  title,
  text_1,
  text_2,
  text_3,
  text_4,
  externalLink_1,
  externalLink_2,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <ThemeProvider theme={projectCardTheme}>
      <StyledProjectCard>
        <Card
          sx={{
            backgroundColor: 'transparent',
          }}
        >
          <CardMedia
            component='img'
            height='40%'
            src={image}
            alt={title}
            sx={cardMediaStyles}
          />
          <CardHeader
            sx={{
              width: '90%',
              ml: 'auto',
              mr: 'auto',
              pb: 3,
            }}
            title={title}
          />

          <CardContent
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-between', sm: 'start' },
              p: 0,
              ml: 'auto',
              mr: 'auto',
              width: '90%',
            }}
          >
            {!isOpen ? (
              <Button
                variant='outlined'
                onClick={handleOpen}
                sx={projectCardButton}
              >
                Description
              </Button>
            ) : (
              <CloseIcon
                sx={{
                  color: '#39ffd0',
                  pr: 3,
                }}
                onClick={handleOpen}
              />
            )}
            <Link
              href={externalLink_1}
              target='_blank'
              sx={{ textDecoration: 'none' }}
            >
              <Button
                variant='outlined'
                sx={
                  //  {
                  //   cursor: 'pointer',
                  //   fontWeight: 500,
                  // }
                  projectCardButton
                }
                onClick={handleOpen}
              >
                Visit site
              </Button>
            </Link>
          </CardContent>
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            <CardContent>
              <Typography>{text_1}</Typography>
              <Typography sx={{ display: 'inline-block' }}>{text_3}</Typography>
              <Link
                sx={[
                  { textDecoration: 'none', cursor: 'pointer', color: 'white' },
                  { '&:hover': { color: '#5446f1' } },
                ]}
                href={externalLink_2}
              >
                {text_4}
              </Link>
              <Typography sx={{ mt: 2 }}>{text_2}</Typography>
            </CardContent>
          </Collapse>
        </Card>
      </StyledProjectCard>
    </ThemeProvider>
  );
};
