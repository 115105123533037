import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { ProjectCard } from '../../page-layout/project-card';
import '../../../assets/page-segments/title.css';
import '../../../assets/page-segments/projects.css';
import '../../../assets/page-layout/segment-links.css';
import climbingCommunity from '../../../assets/images/project-cards/climbingcommunity.png';
import sideSpin from '../../../assets/images/project-cards/sidespin.png';
import greenways from '../../../assets/images/project-cards/greenways-app.png';
import initTracker from '../../../assets/images/project-cards/d-and-d-initiative-tracker.png';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SegmentLink from '../../../components/page-layout/segment-link';
import { Typography } from '@mui/material';
import { colours } from '../../../styles/colours';

export const Projects: React.FC = () => {
  return (
    <div className='projects-body' id='Projects'>
      <div className='page-title-container'>
        <Typography
          variant='h3'
          sx={{
            color: colours.primary,
            pl: 1,
            fontFamily: 'Poppins',
            ml: '5%',
            mt: '5%',
          }}
        >
          Projects
        </Typography>
      </div>

      <ProjectCard
        externalLink_1='https://climbingcommunity.co.uk'
        image={climbingCommunity}
        title='Climbing Community'
        text_1='This is a collaborative project, currently in beta testing. Climbing Community is an app inspired by Strava but focussed on climbing. It lets users set goals, 
        track their progress in the form of graphs, share achievements and make plans with their community.'
        subtitle_2='Stack'
        text_2='Typescript, React, TypeORM, GraphQL'
        subtitle_3='Other info'
        text_3='Feature in development: Buddy Search allows users to find climbing buddies and groups by posting their preferred gym, climbing type, 
        and availability to a notice board.'
      />

      <ProjectCard
        externalLink_1='https://sidespin-730b4.web.app'
        image={sideSpin}
        title='Side Spin'
        text_1="This website allows users to set up a table tennis league. It uses a simplified version of the player rating system used in chess to determine each player's position in the table.
        Users can record game results, score a live game with the Score Keeper feature and keep track of their league with a leaderboard that updates in real time."
        subtitle_2='Stack'
        text_2='Javascript, React, Node, Express, MongoDB'
        subtitle_3='Other info'
      />
      <ProjectCard
        externalLink_1='https://d-and-d-initiative-tracker.web.app'
        image={initTracker}
        title='D&D Inititative Tracker'
        text_1='SPA aid for that helps Game Masters keep track of whose turn it is by allowing them to add and remove their players to a tracker and keep count of how many turns have been taken.'
        subtitle_2='Stack'
        text_2='React, Typescript, Redux Toolkit'
      />
      <ProjectCard
        externalLink_1='https://greenways.app'
        image={greenways}
        title='Greenways Landing Page'
        text_1='A landing page for an app in development. It allows people to join a mailing list for app updates.'
        subtitle_2='Stack'
        text_2='HTML, CSS, MailChimp api handles email distribution.'
        subtitle_3='Other info'
        text_3='Built for &nbsp;'
        text_4='markgotto.com.'
        externalLink_2='https://markgotto.com'
      />

      <div className='link-container'>
        <HashLink to='#About' smooth className='segment-links'>
          <div className='arrow-up'>
            <ArrowUpwardIcon />
          </div>
          top
        </HashLink>
        <HashLink to='#Contact' smooth className='segment-links'>
          <div className='arrow-down'>
            <ArrowDownwardIcon className='arrow-down' />
          </div>
          contact
        </HashLink>
      </div>
    </div>
  );
};
