import React from 'react';
import { HashLink } from 'react-router-hash-link';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { ContactForm } from '../../contact-form';
import '../../../assets/page-segments/title.css';
import '../../../assets/page-segments/contact.css';
import '../../../assets/page-layout/segment-links.css';
import { SocialsLinks } from '../../page-layout/socials-links';
import { Footer } from '../../page-layout/footer';
import { Button, Typography } from '@mui/material';
import { colours } from '../../../styles/colours';

export const Contact: React.FC = () => {
  const handlePDFClick = () => {
    // using Java Script method to get PDF file
    fetch('JoshKnightCV.pdf').then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'JoshKnightCV.pdf';
        alink.click();
      });
    });
  };

  return (
    <div className='contact-body' id='Contact'>
      <div className='title-and-tag'>
        <Typography
          variant='h3'
          sx={{
            color: colours.primary,
            pl: 1,
            fontFamily: 'Poppins',
            ml: '5%',
            mt: '5%',
          }}
        >
          Contact
        </Typography>

        <p className='contact-tag'></p>
      </div>
      <Typography
        sx={{
          color: colours.primary,
          p: 1,
          fontFamily: 'Poppins',
          m: '5%',
        }}
      >
        Please get in touch.
        <br />
        <br />
        If you have any questions, or would like to work with me, I'd love to
        hear from you.
      </Typography>
      <div className='form'>
        <ContactForm />
      </div>
      <div className='socials-and-cv'>
        <SocialsLinks />
        <Button variant='outlined' className='cv-btn' onClick={handlePDFClick}>
          Download a PDF of my CV
        </Button>
      </div>
      <div className='link-container-contact'>
        <HashLink to='#Projects' smooth className='segment-links'>
          <div className='arrow-down'>
            <ArrowUpwardIcon className='arrow-down' />
          </div>
          projects
        </HashLink>
        <HashLink to='#About' smooth className='segment-links'>
          <div className='arrow-up'>
            <ArrowUpwardIcon />
          </div>
          top
        </HashLink>
      </div>
      <Footer />
    </div>
  );
};
