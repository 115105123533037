import { IColours } from '../colours';

export const skillsSection = () => ({
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  justifyContent: { xs: 'center', sm: 'start', lg: 'center' },
  alignContent: { xs: 'center' },
  flexWrap: 'wrap',
  listStyle: 'none',
  mr: 'auto',
  ml: 'auto',
  mt: 0,
  height: { xs: 300, sm: 'auto' },
  width: { xs: '86%', sm: '85%', md: '75%', lg: '100%' },
  p: { xs: 0, sm: 1.8, md: 8.5, lg: 0 },
  background: {
    xs: `linear-gradient(
    116.26deg,
    rgba(255, 255, 255, 0.18) 1.81%,
    rgba(255, 255, 255, 0) 106.19%
  )`,
    lg: 'none',
  },
});

export const skillsChip = (colours: IColours) => ({
  background: `linear-gradient(
    116.26deg,
    rgba(255, 255, 255, 0.18) 1.81%,
    rgba(255, 255, 255, 0) 106.19%
  )`,
  color: colours.primary,
  p: 1,
  m: 1,
});
