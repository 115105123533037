import React from 'react';
import { HashLink } from 'react-router-hash-link';

import '../../../assets/page-segments/title.css';
import droplets from '../../../assets/images/page-backgrounds/droplets.jpg';

import {
  Avatar,
  Box,
  Card,
  Chip,
  keyframes,
  ListItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { colours } from '../../../styles/colours';
import { nameFont } from '../../../styles/name-font';
import { jobTitle } from '../../../styles/job-title';
import SegmentLink from '../../../components/page-layout/segment-link';
import html from '../../../assets/images/language-icons/html-48.png';
import css from '../../../assets/images/language-icons/css-48.png';
import js from '../../../assets/images/language-icons/javascript-48.png';
import react from '../../../assets/images/language-icons/react.png';
import ts from '../../../assets/images/language-icons/ts.png';
import mongo from '../../../assets/images/language-icons/mongo.png';
import node from '../../../assets/images/language-icons/node-js-48.png';
import graphQl from '../../../assets/images/language-icons/graphql.png';
import { skillsChip, skillsSection } from '../../../styles/skills-section';
import {
  aboutStack,
  imageBox,
  linkPaper,
  namePaper,
} from '../../../styles/about-segment';
export const About: React.FC = () => {
  const spin = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }

`;

  const langs = [
    { name: 'React', lang: react, time: '2s' },
    { name: 'Typescript', lang: ts, time: '0.7s' },
    { name: 'Javascript', lang: js, time: '1.5s' },
    { name: 'MongoDB', lang: mongo, time: '0.3s' },
    { name: 'graphQL', lang: graphQl, time: '2.7s' },
    { name: 'Node.JS', lang: node, time: '3s' },
    { name: 'HTML', lang: html, time: '0.9s' },
    { name: 'CSS', lang: css, time: '0.2s' },
  ];

  const skillChips = langs.map((lang, idx) => {
    return (
      <ListItem
        sx={{
          width: 'fit-content',
        }}
      >
        <Chip
          label={lang.name}
          variant='outlined'
          sx={skillsChip(colours)}
          avatar={
            <Avatar
              alt='react'
              src={lang.lang}
              sx={{
                animation: `${spin} 5s infinite`,
                animationDelay: `${lang.time}`,
              }}
            />
          }
        />
      </ListItem>
    );
  });

  return (
    <Stack sx={aboutStack} id='About'>
      <Box component='img' sx={imageBox} alt='Water droplets' src={droplets} />
      <Paper sx={linkPaper(colours)}>
        <HashLink smooth to='#Contact'>
          <SegmentLink text='contact' />
        </HashLink>
        <HashLink smooth to='#Projects'>
          <SegmentLink text='projects' />
        </HashLink>
      </Paper>
      <Paper elevation={0} sx={namePaper}>
        <Typography sx={nameFont(colours)}>Josh Knight</Typography>
        <Typography sx={jobTitle(colours)}>Web Developer</Typography>
      </Paper>
      <Typography
        display='block'
        variant='h6'
        sx={{
          color: colours.primary,
          fontFamily: 'Poppins',
          pl: 1,
          pt: 1,
          pb: 3,
          ml: '5%',
          mt: '5%',
        }}
      >
        About me
      </Typography>
      <Typography
        sx={{
          color: colours.primary,
          pl: 1,
          fontFamily: 'Poppins',
          ml: '5%',
          mr: '5%',
        }}
      >
        I'm a Bristol-based web developer who believes in creating an easy and
        engaging user experience implemented with smart development. Whilst I
        mainly work with React Typescript, I'm always keen to explore new
        technologies and development techniques.
        <br />
        <br />
        I'm looking for job opportunities that allow me to learn, grow and
        contribute in meaningful ways. If you have a good opportunity that
        matches my skills and experience then don't hesitate to contact me.
        <br />
        <br />
        When I'm not coding, I like to rock climb, watch rugby and play
        boardgames.
      </Typography>

      <Typography
        display='block'
        variant='h6'
        sx={{
          color: colours.primary,
          fontFamily: 'Poppins',
          ml: '5%',
          pl: 1,
          pt: 3,
          pb: 3,
        }}
      >
        Skills
      </Typography>
      <Paper elevation={0} sx={skillsSection} component='ul'>
        {skillChips}
      </Paper>
    </Stack>
  );
};
