import { IColours } from '../colours';

export const aboutStack = () => ({
  height: 'fit-content',
  backgroundColor: '#1d1d20',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const imageBox = () => ({
  ml: { lg: 'auto' },
  mr: { lg: 'auto' },
  width: { md: '100%', lg: '70%' },
  height: { md: 'auto', lg: 600 },
  opacity: { lg: '60%' },
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

export const linkPaper = (colours: IColours) => ({
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colours.secondary,
  border: 3,
  borderRight: 'none',
  borderLeft: 'none',
  borderColor: colours.primary,
  borderRadius: 0,
  p: 2,
});

export const namePaper = () => ({
  zIndex: 1000,
  width: '100%',
  position: 'absolute',
  top: { xs: 80, sm: 160, md: 240 },
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
});
