import React, { useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import '../../../assets/components/utils/modal/modal.css';
import IconButton from '@mui/material/IconButton';

interface IModalProps {
  title: String;
  subtitle: String;
  handleClose: () => void;
  isResultOpenModal: Boolean;
}

export const Modal: React.FC<IModalProps> = ({
  title,
  subtitle,
  handleClose,
}) => {
  return (
    <div className='modal'>
      <IconButton
        sx={{
          alignSelf: 'flex-end',
          color: '#5446f1 ',
          textDecoration: 'none',
          borderRadius: '50%',
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <div className='modal-title'>{title}</div>
      <div className='subtitle'>{subtitle}</div>
    </div>
  );
};
