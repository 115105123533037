import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { SegmentLink, segmentLinkTheme } from '../../../styles/segment-links';

interface IStyledSegmentLinkProps {
  text: string;
}
const StyledSegmentLink: React.FC<IStyledSegmentLinkProps> = ({ text }) => {
  return (
    <ThemeProvider theme={segmentLinkTheme}>
      <SegmentLink>{text}</SegmentLink>
    </ThemeProvider>
  );
};

export default StyledSegmentLink;
