import { styled, createTheme } from '@mui/material/styles';
import { colours } from '../colours';
export const projectCardTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',

    h1: {
      color: colours.primary,
      fontSize: 30,
      mb: 5,
    },
    h5: {
      color: colours.primary,
      fontSize: 18,
    },
    body1: {
      color: colours.primary,
      fontSize: 15,
    },
  },
});

export const StyledProjectCard = styled('div')`
  ${({ theme }) => `
 cursor: pointer;
width: 60%;
margin: auto;
margin-top: 5%;
margin-bottom: 5%;
max-width: 60%;
border-radius: 15px;
box-sizing: border-box;
border: 7px solid;
border-image: linear-gradient(180deg, black, white) 1;
 background: linear-gradient(
    116.26deg,
    rgba(255, 255, 255, 0.18) 1.81%,
    rgba(255, 255, 255, 0) 106.19%
  );
  backdrop-filter: blur(7.5px);

`}
`;

export const cardMediaStyles = () => ({
  objectFit: 'contain',
  width: '90%',
  ml: 'auto',
  mr: 'auto',
  mt: { xs: 1, md: 5 },
  p: 1,
});
